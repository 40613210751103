import { createRouter, createWebHashHistory } from 'vue-router'

function isAuthenticated() {
  // Exemplo básico usando localStorage (adaptar conforme sua implementação)
  return localStorage.getItem('usuario') !== null;
}

const routes = [

  //ROUTES FOR ADMIN
  {
    path: '/',
    name: 'admin_login',
    component: () => import('../views/admin/login.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/cadastrar-empresa',
    name: 'admin_cadastro',
    component: () => import('../views/admin/cadastro.vue'),
    meta: { requiresAuth: false } 
  },
  {
    path: '/dashboard',
    name: 'admin_dashboard',
    component: () => import('../views/admin/dashboard.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/perfil',
    name: 'admin_profile',
    component: () => import('../views/admin/profile.vue'),
    meta: { requiresAuth: false } 
  },

  //CONFIGURACAO
  {
    path: '/admin/configuracao',
    name: 'admin_config',
    component: () => import('../views/admin/configuracao/configuracao_view.vue'),
    meta: { requiresAuth: true } 
  },

  //SECRETARY
  {
    path: '/admin/secretaria/parceiros',
    name: 'admin_secretary_partners',
    component: () => import('../views/admin/secretaria/parceiro_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/secretaria/motoristas',
    name: 'admin_secretary_motoristas',
    component: () => import('../views/admin/secretaria/motorista_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/secretaria/clientes',
    name: 'admin_secretary_clientes',
    component: () => import('../views/admin/secretaria/cliente_view.vue'),
    meta: { requiresAuth: true } 
  },

  //FROTAS
  {
    path: '/admin/frotas/veiculos',
    name: 'admin_frotas_veiculo',
    component: () => import('../views/admin/frotas/veiculo_view.vue'),
    meta: { requiresAuth: true } 
  },

  //FINANCES
  {
    path: '/admin/finances/contratos',
    name: 'admin_finances_contrato',
    component: () => import('../views/admin/financas/contratos_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/finances/entrada',
    name: 'admin_finances_entrada',
    component: () => import('../views/admin/financas/entrada_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/finances/saidas',
    name: 'admin_finances_saida',
    component: () => import('../views/admin/financas/saida_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/finances/fluxo-de-caixa',
    name: 'admin_finances_fluxo_de_caixa',
    component: () => import('../views/admin/financas/fluxo_de_caixa_view.vue'),
    meta: { requiresAuth: true } 
  },

  //VITRINE
  {
    path: '/noticias',
    name: 'noticias',
    component: () => import('../views/admin/vitrine/noticia_view.vue')
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: () => import('../views/admin/vitrine/servico_view.vue')
  },

  //AUTENTICAÇÃO
  {
    path: '/admin/autenticacao/users',
    name: 'admin_autenticacao_users',
    component: () => import('../views/admin/autenticacao/users_view.vue'),
    meta: { requiresAuth: true } 
  },
  {
    path: '/admin/autenticacao/logout',
    name: 'admin_autenticacao_logout',
    component: () => import('../views/admin/logout.vue'),
    meta: { requiresAuth: true } 
  },

  //UTILS
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/404_view.vue'),
    meta: { requiresAuth: false } 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// Guarda de navegação para verificar autenticação
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
